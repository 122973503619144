<template>
  <div style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @ok="ok" @hidden="hidden">
      
      <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>
      
      <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height" id="task-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :columnDefs="columnDefs"
            :context="context"
            :defaultColDef="defaultColDef"
            :getRowId="params => params.data.uuId"
            :overlayNoRowsTemplate="overlayNoRowsTemplate"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :rowData="rowData"
            :sideBar="false"
            suppressDragLeaveHidesColumns
            suppressCellFocus
            suppressContextMenu
            suppressMultiSort
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="allowSelect">
          <b-button :disabled="disableOk" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('MANAGE' === mode?'button.close':'button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>
  </div>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import PercentageCellRenderer from '@/components/Aggrid/CellRenderer/Percentage';
import DurationCellRenderer from '@/components/Aggrid/CellRenderer/Duration';
import { viewProfileService } from '@/services';
import { columnDefSortFunc } from '@/views/management/script/common';

export default {
  name: 'TaskSelectorModal',
  components: {
    'ag-grid-vue': AgGridVue,
    AlertFeedback: () => import('@/components/AlertFeedback'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    'percentageCellRenderer': PercentageCellRenderer,
    'durationCellRenderer': DurationCellRenderer
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: null
    },
    preselected: {
      type: String,
      default: null
    },
    data: {
      type: Array,
      default: null
    }
  },
  data: function() {
    return {
      permissionName: 'TASK',
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      modalShow: false,
      gridOptions: null,
      gridApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,

      selected: [],
      
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,
      
      searchFilter: '',
      disableOk: true,
      lastOpenColumnMenuParams: null
    };
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId;
    const profileKey = 'task_selector_list';
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }

    const self = this;
    this.gridOptions = {  
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
        self.disableEdit = self.disableDuplicate = self.selected.length != 1;
        self.disableDelete = self.selected.length < 1;
        self.disableOk = (self.multiple? (self.selected.length < 1): (self.selected.length != 1));
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.api
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.api.moveColumns([colKey], columnMenuColumnIndex + 1);
        }

        const cols = params.api.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.api.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.api.moveColumns([c.colId], index);
        }

        const columns = params.api.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      onSortChanged: function(event) {
        const columns = event.api.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onDragStopped: function(event) {
        const columns = event.api.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onFirstDataRendered: function(event) {
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null;
          self.gridApi.sizeColumnsToFit();
          self.$nextTick(() => {
            const columns = event.api.getAllDisplayedColumns();
            self.settings[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateViewProfile();
          })
        }
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('task.field.name'),
        field: 'path',
        lockVisible: true,
        minWidth: 300,
        width: 320,
        hide: false,
        sort: 'asc',
        checkboxSelection: true
      },
      {
        headerName: this.$t('task.field.taskType'),
        field: 'type',
        valueFormatter: params => params.value.toUpperCase(),
        minWidth: 100,
        maxWidth: 100,
        hide: false
      },
      {
        headerName: this.$t('task.field.progress'),
        field: 'progress',
        cellRendererSelector: params => {
          if (params.data.type === 'task') {
            return { component: 'percentageCellRenderer' };
          }
          return undefined;
        },
        minWidth: 50,
        maxWidth: 150,
        hide: false
      },
      {
        headerName: this.$t('task.field.estimatedDuration'),
        field: 'estimatedDuration',
        hide: false,
        cellRenderer: 'durationCellRenderer',
        minWidth: 50,
        maxWidth: 150,
        cellStyle: {
          'border-width': '2px',
          'height': '100%',
          'display': 'flex ',
          'align-items': 'center '
        }
      }
    ];
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      hide: true,
      menuTabs: ['columnsMenuTab']
    };
    this.autoGroupColumnDef = {
      headerName: this.$t('task.field.name'),
      field: 'name',
      cellRendererParams: { 
        checkbox: true,
        suppressCount: true 
      }
    };
    this.context = {
      componentParent: self
    };
  },
  mounted() {
    this.loadViewProfile();
  },
  created() {
    this.updateModalShow(this.show);
  },
  beforeDestroy() {
    this.userId = null;
    this.newToProfile = null;
    this.gridApi = null;
  },
  watch: {
    async show(newValue) {
      if(newValue) {
        this.resetAlert();
        this.searchFilter = "";
        await this.loadViewProfile();
        this.rowData = this.data;
      } else {
        //When modal is hidden/closed, grid is destroyed. All the references become obsolete and should be released to avoid memory leak.
        this.gridApi = null;
      }
      this.updateModalShow(newValue);
    }
  },
  computed: {
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('error.grid_data_loading') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    labelTitle() {
      return this.title? this.title : this.$t('task.title_selector');
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    handleRowHovered(item, index, event) {
      event.target.lastElementChild.classList.toggle('d-none');
      event.target.lastElementChild.classList.toggle('d-flex');
    },
    handleRowUnhovered(item, index, event) {
      event.target.lastElementChild.classList.toggle('d-none');
      event.target.lastElementChild.classList.toggle('d-flex');
    },
    updateChildNodes() {
      if (this.gridApi.getSelectedNodes().length === 0) {
        this.hasChildNodes = false;
      }
      else {
        const node = this.gridApi.getSelectedNodes()[0];
        this.hasChildNodes = typeof node.group !== 'undefined';
      }
    },
    scrollToTop() {
      document.querySelector(`#${this.id}`).scrollIntoView();
    },
    ok() {
      const details = this.gridApi.getSelectedNodes().map(i => { return { taskUuid: i.data.uuId, projectUuid: i.data.projectUuid, type: i.data.type }});

      this.$emit('ok', details[0]);
    },
    hidden() {
      this.alert = null;
      this.selected.splice(0, this.selected.length);
      this.$emit('update:show', false);
      this.$emit('cancel');
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    updateViewProfile() {
      viewProfileService.update([this.settings], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async loadViewProfile() {
      const self = this;
      await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {  
        const profileData = value;
        if (profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
         
          if (typeof self.settings.task_selector_list !== 'undefined') {
            self.loadColumnSettings(self, self.settings.task_selector_list);
          } else {
            self.newToProfile = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColumnSettings(data, columns) {
      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = data.columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          data.columnDefs.splice(idx++, 0, data.columnDefs.splice(index, 1)[0]);
        }
      });
      
      let valid = false;
      for (const column of data.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          valid = true;
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }
      
      if (!valid) {
        // no columns are shown, show the 
        for (const def of this.columnDefs) {
          def.hide = false;
        }
        
        if (data.gridApi) {
          data.gridApi.setGridOption('columnDefs', this.columnDefs);
        }
      }
      
      if (data != null && data.gridApi != null) {
        data.gridApi.setGridOption('columnDefs', []);
        data.gridApi.setGridOption('columnDefs', data.columnDefs);
      }
      return false;
    },
    fileImport() {
      this.docImportShow = true;
    },
    processCellCallback(/**self */) {
      return function(params) {
        if (params.column.colId.indexOf('uuId') !== -1) {
          return params.node.data.name;
        }
        return params.value;
      }
    }
  }
}


</script>